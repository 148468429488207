import React, { useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import Main from '../components/Company/Main'
import Switcher from '../components/Company/Switcher'
import Whychoose from '../components/Company/Whychoose'

function Company() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='pt-[60px] bg-[#EDF2EF] relative overflow-hidden'>
      <Main />
      <LazyLoad><Switcher /></LazyLoad>
      <LazyLoad><Whychoose /></LazyLoad>
    </div>
  );
}

export default Company;