import React from 'react';

import { useTranslation } from 'react-i18next';

import woman from '../../img/Services/woman.png';
import vector2 from '../../img/Services/vectoryellow.png';
import Fade from 'react-reveal/Fade'

function Main() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#DEE2E1] h-[858px]] md:h-[980px] xl:h-[790px] flex'>
        <div className='mx-auto hidden xl:flex'>
            <div className='mr-[150px] mt-[100px] z-10'>
                <Fade left duration={500} delay={500}><h1 className='text-[#493843] text-[20px] md:text-[36px] xl:text-[40px] font-medium manrope'>{t('sections.title')}</h1></Fade>
                <Fade left duration={500} delay={800}><h1 className='max-w-[552px] textcolor text-[16px] xl:text-[16px] font-light roboto mt-[20px]'>{t('sections.description')}</h1></Fade>
                <Fade left duration={500} delay={1000}><div className='flex mt-[60px] gap-[40px]'>
                    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_253_500)">
                            <path d="M12.6002 38.5792C8.90397 31.4562 4.73114 24.5857 0.109631 18.0136C0.026205 17.9025 -0.0146384 17.7656 -0.00560287 17.6275C0.00343269 17.4894 0.0617629 17.3589 0.158963 17.2594C0.256163 17.1599 0.385901 17.0979 0.525041 17.0843C0.664181 17.0708 0.803657 17.1066 0.918568 17.1855C4.89369 19.9015 8.72414 22.8195 12.3946 25.9276C12.4723 25.9951 12.5632 26.046 12.6616 26.0773C12.7601 26.1085 12.864 26.1194 12.9668 26.1092C13.0697 26.099 13.1693 26.068 13.2596 26.0181C13.3498 25.9681 13.4288 25.9004 13.4914 25.819C16.2336 22.5475 29.4235 7.08595 45.2733 0.0271272C45.3892 -0.0325108 45.5238 -0.0455985 45.6492 -0.00941106C45.7746 0.0267764 45.8811 0.109448 45.9464 0.221397C46.0118 0.333346 46.031 0.465944 46.0001 0.591585C45.9692 0.717226 45.8906 0.826232 45.7806 0.895905C30.1639 12.2579 17.1385 33.2579 13.985 38.5927C13.9145 38.7135 13.8129 38.8136 13.6906 38.8827C13.5682 38.9518 13.4295 38.9875 13.2886 38.9861C13.1478 38.9847 13.0098 38.9463 12.8888 38.8748C12.7679 38.8033 12.6683 38.7013 12.6002 38.5792Z" fill="#F5B700"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_253_500">
                                <rect width="46" height="39" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div>
                        <h1 className='text-[#493843] manrope text-[20px] xl:text-[24px] font-medium'>{t('sections.points.title1')}</h1>
                        <p className='mt-[10px] textcolor text-[14px] xl:text-[16px] roboto font-light max-w-[413px]'>{t('sections.points.description1')}</p>
                    </div>
                </div></Fade>
                <Fade left duration={500} delay={1200}><div className='flex mt-[40px] gap-[40px]'>
                    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_253_500)">
                            <path d="M12.6002 38.5792C8.90397 31.4562 4.73114 24.5857 0.109631 18.0136C0.026205 17.9025 -0.0146384 17.7656 -0.00560287 17.6275C0.00343269 17.4894 0.0617629 17.3589 0.158963 17.2594C0.256163 17.1599 0.385901 17.0979 0.525041 17.0843C0.664181 17.0708 0.803657 17.1066 0.918568 17.1855C4.89369 19.9015 8.72414 22.8195 12.3946 25.9276C12.4723 25.9951 12.5632 26.046 12.6616 26.0773C12.7601 26.1085 12.864 26.1194 12.9668 26.1092C13.0697 26.099 13.1693 26.068 13.2596 26.0181C13.3498 25.9681 13.4288 25.9004 13.4914 25.819C16.2336 22.5475 29.4235 7.08595 45.2733 0.0271272C45.3892 -0.0325108 45.5238 -0.0455985 45.6492 -0.00941106C45.7746 0.0267764 45.8811 0.109448 45.9464 0.221397C46.0118 0.333346 46.031 0.465944 46.0001 0.591585C45.9692 0.717226 45.8906 0.826232 45.7806 0.895905C30.1639 12.2579 17.1385 33.2579 13.985 38.5927C13.9145 38.7135 13.8129 38.8136 13.6906 38.8827C13.5682 38.9518 13.4295 38.9875 13.2886 38.9861C13.1478 38.9847 13.0098 38.9463 12.8888 38.8748C12.7679 38.8033 12.6683 38.7013 12.6002 38.5792Z" fill="#F5B700"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_253_500">
                                <rect width="46" height="39" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div>
                        <h1 className='text-[#493843] manrope text-[20px] xl:text-[24px] font-medium'>{t('sections.points.title2')}</h1>
                        <p className='mt-[10px] textcolor text-[14px] xl:text-[16px] roboto font-light max-w-[413px]'>{t('sections.points.description2')}</p>
                    </div>
                </div></Fade>
                <Fade left duration={500} delay={1400}><div className='flex mt-[40px] gap-[40px]'>
                    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_253_500)">
                            <path d="M12.6002 38.5792C8.90397 31.4562 4.73114 24.5857 0.109631 18.0136C0.026205 17.9025 -0.0146384 17.7656 -0.00560287 17.6275C0.00343269 17.4894 0.0617629 17.3589 0.158963 17.2594C0.256163 17.1599 0.385901 17.0979 0.525041 17.0843C0.664181 17.0708 0.803657 17.1066 0.918568 17.1855C4.89369 19.9015 8.72414 22.8195 12.3946 25.9276C12.4723 25.9951 12.5632 26.046 12.6616 26.0773C12.7601 26.1085 12.864 26.1194 12.9668 26.1092C13.0697 26.099 13.1693 26.068 13.2596 26.0181C13.3498 25.9681 13.4288 25.9004 13.4914 25.819C16.2336 22.5475 29.4235 7.08595 45.2733 0.0271272C45.3892 -0.0325108 45.5238 -0.0455985 45.6492 -0.00941106C45.7746 0.0267764 45.8811 0.109448 45.9464 0.221397C46.0118 0.333346 46.031 0.465944 46.0001 0.591585C45.9692 0.717226 45.8906 0.826232 45.7806 0.895905C30.1639 12.2579 17.1385 33.2579 13.985 38.5927C13.9145 38.7135 13.8129 38.8136 13.6906 38.8827C13.5682 38.9518 13.4295 38.9875 13.2886 38.9861C13.1478 38.9847 13.0098 38.9463 12.8888 38.8748C12.7679 38.8033 12.6683 38.7013 12.6002 38.5792Z" fill="#F5B700"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_253_500">
                                <rect width="46" height="39" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div>
                        <h1 className='text-[#493843] manrope text-[20px] xl:text-[24px] font-medium'>{t('sections.points.title3')}</h1>
                        <p className='mt-[10px] textcolor text-[14px] xl:text-[16px] roboto font-light max-w-[413px]'>{t('sections.points.description3')}</p>
                    </div>
                </div></Fade>
            </div>
            <div>
                <Fade right duration={2000}><img src={woman} alt="" className='relative z-10 mt-[5px] ml-[200px]'/></Fade>
                <Fade right duration={2000}><img src={vector2} alt="" className='absolute top-[60px]'/></Fade>
            </div>
        </div>
        <div className='flex flex-col xl:hidden mx-auto'>
            <div className='mt-[50px] mx-[20px] z-10'>
            <Fade left duration={500} delay={500}><h1 className='text-[#493843] text-[32px] md:text-[36px] xl:text-[40px] font-medium manrope'>{t('sections.title')}</h1></Fade>
                <Fade left duration={500} delay={800}><h1 className='max-w-[552px] textcolor text-[16px] xl:text-[16px] font-light roboto mt-[20px]'>{t('sections.description')}</h1></Fade>
            </div>
            <div className='relative z-50 mx-[20px]'>
                <Fade left duration={500} delay={1000}><div className='flex mt-[30px] gap-[40px]'>
                    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_253_500)">
                            <path d="M12.6002 38.5792C8.90397 31.4562 4.73114 24.5857 0.109631 18.0136C0.026205 17.9025 -0.0146384 17.7656 -0.00560287 17.6275C0.00343269 17.4894 0.0617629 17.3589 0.158963 17.2594C0.256163 17.1599 0.385901 17.0979 0.525041 17.0843C0.664181 17.0708 0.803657 17.1066 0.918568 17.1855C4.89369 19.9015 8.72414 22.8195 12.3946 25.9276C12.4723 25.9951 12.5632 26.046 12.6616 26.0773C12.7601 26.1085 12.864 26.1194 12.9668 26.1092C13.0697 26.099 13.1693 26.068 13.2596 26.0181C13.3498 25.9681 13.4288 25.9004 13.4914 25.819C16.2336 22.5475 29.4235 7.08595 45.2733 0.0271272C45.3892 -0.0325108 45.5238 -0.0455985 45.6492 -0.00941106C45.7746 0.0267764 45.8811 0.109448 45.9464 0.221397C46.0118 0.333346 46.031 0.465944 46.0001 0.591585C45.9692 0.717226 45.8906 0.826232 45.7806 0.895905C30.1639 12.2579 17.1385 33.2579 13.985 38.5927C13.9145 38.7135 13.8129 38.8136 13.6906 38.8827C13.5682 38.9518 13.4295 38.9875 13.2886 38.9861C13.1478 38.9847 13.0098 38.9463 12.8888 38.8748C12.7679 38.8033 12.6683 38.7013 12.6002 38.5792Z" fill="#F5B700"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_253_500">
                                <rect width="46" height="39" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div>
                        <h1 className='text-[#493843] manrope text-[20px] xl:text-[24px] font-medium'>{t('sections.points.title1')}</h1>
                        <p className='mt-[10px] textcolor text-[14px] xl:text-[16px] roboto font-light max-w-[413px]'>{t('sections.points.description1')}</p>
                    </div>
                </div></Fade>
                <Fade left duration={500} delay={1200}><div className='flex mt-[20px] gap-[40px]'>
                    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_253_500)">
                            <path d="M12.6002 38.5792C8.90397 31.4562 4.73114 24.5857 0.109631 18.0136C0.026205 17.9025 -0.0146384 17.7656 -0.00560287 17.6275C0.00343269 17.4894 0.0617629 17.3589 0.158963 17.2594C0.256163 17.1599 0.385901 17.0979 0.525041 17.0843C0.664181 17.0708 0.803657 17.1066 0.918568 17.1855C4.89369 19.9015 8.72414 22.8195 12.3946 25.9276C12.4723 25.9951 12.5632 26.046 12.6616 26.0773C12.7601 26.1085 12.864 26.1194 12.9668 26.1092C13.0697 26.099 13.1693 26.068 13.2596 26.0181C13.3498 25.9681 13.4288 25.9004 13.4914 25.819C16.2336 22.5475 29.4235 7.08595 45.2733 0.0271272C45.3892 -0.0325108 45.5238 -0.0455985 45.6492 -0.00941106C45.7746 0.0267764 45.8811 0.109448 45.9464 0.221397C46.0118 0.333346 46.031 0.465944 46.0001 0.591585C45.9692 0.717226 45.8906 0.826232 45.7806 0.895905C30.1639 12.2579 17.1385 33.2579 13.985 38.5927C13.9145 38.7135 13.8129 38.8136 13.6906 38.8827C13.5682 38.9518 13.4295 38.9875 13.2886 38.9861C13.1478 38.9847 13.0098 38.9463 12.8888 38.8748C12.7679 38.8033 12.6683 38.7013 12.6002 38.5792Z" fill="#F5B700"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_253_500">
                                <rect width="46" height="39" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div>
                        <h1 className='text-[#493843] manrope text-[20px] xl:text-[24px] font-medium'>{t('sections.points.title2')}</h1>
                        <p className='mt-[10px] textcolor text-[14px] xl:text-[16px] roboto font-light max-w-[413px]'>{t('sections.points.description2')}</p>
                    </div>
                </div></Fade>
                <Fade left duration={500} delay={1400}><div className='flex mt-[20px] gap-[40px]'>
                    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_253_500)">
                            <path d="M12.6002 38.5792C8.90397 31.4562 4.73114 24.5857 0.109631 18.0136C0.026205 17.9025 -0.0146384 17.7656 -0.00560287 17.6275C0.00343269 17.4894 0.0617629 17.3589 0.158963 17.2594C0.256163 17.1599 0.385901 17.0979 0.525041 17.0843C0.664181 17.0708 0.803657 17.1066 0.918568 17.1855C4.89369 19.9015 8.72414 22.8195 12.3946 25.9276C12.4723 25.9951 12.5632 26.046 12.6616 26.0773C12.7601 26.1085 12.864 26.1194 12.9668 26.1092C13.0697 26.099 13.1693 26.068 13.2596 26.0181C13.3498 25.9681 13.4288 25.9004 13.4914 25.819C16.2336 22.5475 29.4235 7.08595 45.2733 0.0271272C45.3892 -0.0325108 45.5238 -0.0455985 45.6492 -0.00941106C45.7746 0.0267764 45.8811 0.109448 45.9464 0.221397C46.0118 0.333346 46.031 0.465944 46.0001 0.591585C45.9692 0.717226 45.8906 0.826232 45.7806 0.895905C30.1639 12.2579 17.1385 33.2579 13.985 38.5927C13.9145 38.7135 13.8129 38.8136 13.6906 38.8827C13.5682 38.9518 13.4295 38.9875 13.2886 38.9861C13.1478 38.9847 13.0098 38.9463 12.8888 38.8748C12.7679 38.8033 12.6683 38.7013 12.6002 38.5792Z" fill="#F5B700"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_253_500">
                                <rect width="46" height="39" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div>
                        <h1 className='text-[#493843] manrope text-[20px] xl:text-[24px] font-medium'>{t('sections.points.title3')}</h1>
                        <p className='mt-[10px] textcolor text-[14px] xl:text-[16px] roboto font-light max-w-[413px]'>{t('sections.points.description3')}</p>
                    </div>
                </div></Fade>
            </div>
            <div className='mt-[40px]'>
                <Fade right duration={2000}><img src={woman} alt="" className='relative z-10 w-[208.818px] md:w-[413.581px] mx-auto'/></Fade>
            </div>
            <Fade right duration={2000}><img src={vector2} alt="" className='absolute bottom-[-500px] z-0'/></Fade>
        </div>
    </div>
  );
}

export default Main;