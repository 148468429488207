import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";

import { Link } from 'react-router-dom';

import logo from '../img/Vector.png'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { slide as Menu } from 'react-burger-menu'

function Header() {
  const languages = [
    { value: 'en', label: 'EN' },
    { value: 'ro', label: 'RO' },
    { value: 'es', label: 'ES' },
    { value: 'it', label: 'IT' },
  ]
  const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
      setCurrentLang(selectedLang.value);
      i18n.changeLanguage(selectedLang.value);
      Cookies.set("i18next", selectedLang.value);
  };
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);

  };
  useEffect(() => {
    // Добавляем/удаляем класс 'menu-open' у body при открытии/закрытии меню
    if (menuOpen) {
      document.body.classList.add('menu-open');
      document.body.classList.remove('menu-closed');
    } else {
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    }

    return () => {
      // Удаляем класс при размонтировании компонента
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    };
  }, [menuOpen]);
  return (
    <>
    {/* Header PC */}
    <div className='bg-[#EDF2EF] h-[60px] w-full hidden xl:flex fixed z-50'>
        <Link to="/"><img src={logo} alt="" className='mt-[10px] md:ml-[50px] xl:ml-[150px] w-[80px]'/></Link>
        <div className='flex gap-[40px] mt-[20px] ml-[20px]'>
          <Link to="/products" className='font-normal text-[16px] roboto textcolor'>{t('header_Products')}</Link>
          <Link to="/services" className='font-normal text-[16px] roboto textcolor'>{t('header_Services')}</Link>
          <Link to="/accounts" className='font-normal text-[16px] roboto textcolor'>{t('header_Account')}</Link>
          <Link to="/company" className='font-normal text-[16px] roboto textcolor'>{t('header_Company')}</Link>
          <Link to="/help" className='font-normal text-[16px] roboto textcolor'>{t('header_Help')}</Link>
      </div>
      <div className='flex ml-auto'>
          <Dropdown
            options={languages}
            placeholder="EN"
            className='mt-[10px] mr-[20px]'
            value={languages.find(lang => lang.value === currentLang)}
            onChange={handleChangeLang}
          />
        <div onClick={() => window.location.href = 'https://user.opti-trade.ltd'} className='cursor-pointer bg-[#F5B700] w-[120px] h-[39px] rounded-[40px] md:mr-[50px] xl:mr-[150px] mt-[10px]'>
          <p className='roboto #493843 text-[16px] font-normal text-center mt-[7px]'>{t('header_Log')}</p>
        </div>
      </div>
    </div>
    {/* Header Mobile */}
    <div className='flex xl:hidden bg-[#EDF2EF] h-[60px] w-full fixed z-[999]'>
      <Link to="/"><img src={logo} alt="" className='mt-[10px] ml-[10px] w-[80px]'/></Link>
      <div className='flex ml-auto mr-[50px]'>
        <Dropdown
              options={languages}
              placeholder="EN"
              className='mt-[10px] mr-[20px]'
              value={languages.find(lang => lang.value === currentLang)}
              onChange={handleChangeLang}
            />
        <div className='mt-[15px]'>
          <Menu right isOpen={menuOpen} onStateChange={handleStateChange}>
              <Link to="/products" className='font-normal text-[16px] roboto text-center' onClick={closeMenu}>{t('header_Products')}</Link>
              <Link to="/services" className='font-normal text-[16px] roboto mt-[20px] text-center' onClick={closeMenu}>{t('header_Services')}</Link>
              <Link to="/accounts" className='font-normal text-[16px] roboto mt-[20px] text-center' onClick={closeMenu}>{t('header_Account')}</Link>
              <Link to="/company" className='font-normal text-[16px] roboto mt-[20px] text-center' onClick={closeMenu}>{t('header_Company')}</Link>
              <Link to="/help" className='font-normal text-[16px] roboto mt-[20px] text-center' onClick={closeMenu}>{t('header_Help')}</Link>
                <div onClick={() => {window.location.href = 'https://user.opti-trade.ltd'; closeMenu();}} className='bg-[#F5B700] w-[84px] h-[39px] rounded-[40px] mx-auto mt-[30px] cursor-pointer'> 
                  <p className='roboto text-[#493843] text-[16px] font-normal text-center pt-[7px]'>{t('header_Log')}</p>
                </div>
          </Menu>
        </div>
      </div>
    </div>
    </>
  );
}

export default Header;