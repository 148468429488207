import React from 'react';
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import $ from 'jquery';

import { images } from "./utils/imagesToLoad";

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home'
import Products from './pages/Products'
import Services from './pages/Services'
import Accounts from './pages/Accounts'
import Company from './pages/Company'
import Help from './pages/Help'

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 

  return (
    <Router>
      <Header></Header>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/company" element={<Company />} />
          <Route path="/help" element={<Help />} />
        </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;