import React, { useEffect } from 'react';

import Popular from '../components/Products/PopularTrade'

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='pt-[60px] bg-[#EDF2EF] relative overflow-hidden'>
      <Popular/>
    </div>
  );
}

export default Products;