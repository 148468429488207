import React from 'react';

import { useTranslation } from 'react-i18next';

import trade1 from '../../img/Services/confidence1.png'
import trade2 from '../../img/Services/confidence2.png'
import trade3 from '../../img/Services/confidence3.png'

import Fade from 'react-reveal/Fade'

function TradeConfidence() {

    const { t } = useTranslation();

  return (
<div className='bg-[#EDF2EF] z-10 relative'>
    <div className='max-w-[1280px] mx-auto pt-[120px] xl:pt-[160px] z-10 relative'>
        <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] text-center font-medium ml-[10px] xl:ml-0 mr-[10px]'>{t('trade1')}</h1>
    </div>
    <div className='max-w-[1280px] mx-auto pt-[40px] z-10 relative'>
        <div className='flex flex-wrap gap-x-[150px] gap-y-[20px] justify-center'>
            <Fade bottom delay={300}><div>
                <img src={trade1} alt="" className='mx-auto xl:w-[228px] xl:h-[228px] w-[120px] h-[120px]'/>
                <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>{t('trade2')}</h1>
                <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>{t('trade3')}</p>
            </div></Fade>
            <Fade bottom delay={600}><div>
                <img src={trade2} alt="" className='mx-auto xl:w-[228px] xl:h-[228px] w-[120px] h-[120px]'/>
                <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>{t('trade4')}</h1>
                <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>{t('trade5')}</p>
            </div></Fade>
            <Fade bottom delay={900}><div>
                <img src={trade3} alt="" className='mx-auto xl:w-[228px] xl:h-[228px] w-[120px] h-[120px]'/>
                <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>{t('trade6')}</h1>
                <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>{t('trade7')}</p>
            </div></Fade>
        </div>
    </div>
</div>

  );
}

export default TradeConfidence;