import React, { useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import Main from '../components/Services/Main'
import TradeConfidence from '../components/Services/TradeConfidence'
import GetStarted from '../components/Services/GetStarted';
import PAMM from '../components/Services/PAMM';

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='pt-[60px] bg-[#EDF2EF] relative overflow-hidden'>
      <LazyLoad><Main /></LazyLoad>
      <LazyLoad><TradeConfidence /></LazyLoad>
      <LazyLoad><GetStarted /></LazyLoad>
      <LazyLoad><PAMM /></LazyLoad>
    </div>
  );
}

export default Services;