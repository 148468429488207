import React, { useRef, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

import { useTranslation } from 'react-i18next';

import switcher1 from '../../img/Company/switcher1.png';
import switcher2 from '../../img/Company/switcher2.png';
import switcher3 from '../../img/Company/switcher3.png';
import switcher4 from '../../img/Company/switcher4.png';

function Switcher() {
  const { t } = useTranslation();

  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [contentHeights, setContentHeights] = useState([]);
  const contentRefs = useRef([]);

  useEffect(() => {
    const preloadImages = () => {
      const images = [switcher1, switcher2, switcher3, switcher4];
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          if (images.indexOf(image) === images.length - 1) {
            setImagesLoaded(true);
          }
        };
      });
    };

    preloadImages();
  }, []);

  useEffect(() => {
    const heights = contentRefs.current.map(ref => ref.clientHeight);
    setContentHeights(heights);
  }, [activeIndex]);

  const blocks = [
    {
      title: 'company5',
      content: "company6",
    },
    {
      title: 'company7',
      content: "company8",
    },
    {
      title: 'company9',
      content: "company10",
    },
    {
      title: 'company11',
      content: "company12",
    }
  ];

  return (
    <div className='bg-[#EDF2EF]'>
      <div className='flex max-w-[1280px] xl:mx-auto pt-[60px] xl:pt-[140px] mx-[20px]'>
        {imagesLoaded && (
          <div className='xl:block hidden'>
            {activeIndex === 0 && (<Fade><img src={switcher1} alt="" /></Fade>)}
            {activeIndex === 1 && (<Fade><img src={switcher2} alt="" /></Fade>)}
            {activeIndex === 2 && (<Fade><img src={switcher3} alt="" /></Fade>)}
            {activeIndex === 3 && (<Fade><img src={switcher4} alt="" className='mix-blend-darken'/></Fade>)}
          </div>
        )}
        <div>
          <div className='w-[4px] h-[570px] xl:h-[411px] bg-[#C9C9C9] absolute'></div>
          <div className={`w-[4px] bg-[#F5B700] relative z-10`} style={{ height: `${contentHeights[activeIndex]+10}px`, top: `${contentRefs.current[activeIndex] ? contentRefs.current[activeIndex].offsetTop-980 : 0}px` }}></div>
        </div>
        <div className='ml-[30px] xl:ml-[100px] h-[500px] xl:h-[400px] flex flex-col xl:justify-center'>
          {blocks.map((block, index) => (
            <div key={index} ref={el => contentRefs.current[index] = el}>
              <h1 className={`mt-[${index === 0 ? '0px' : '20px'}] cursor-pointer manrope text-[#493843] text-[24px] font-medium`} onClick={() => setActiveIndex(index)}>{t(block.title)}</h1>
              {activeIndex === index && (
                <Fade><div>
                  <p className={`h-[${block.height}px] xl:h-auto mt-[12px] max-w-[703px] textcolor roboto text-[16px] font-light`}>{t(block.content)}</p>
                </div></Fade>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Switcher;
