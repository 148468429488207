import React from 'react';

import { useTranslation } from 'react-i18next';

import img1 from '../../img/Accounts/perfectmoney.png'
import img2 from '../../img/Accounts/mastercard.png'
import img3 from '../../img/Accounts/visa.png'
import img4 from '../../img/Accounts/btcx.png'
import img5 from '../../img/Accounts/tezer.png'
import img6 from '../../img/Accounts/btc.png'

function Swift() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF] pt-[120px] xl:pt-[160px]'>
        <div className='max-w-[1280px] mx-auto'>
            <div className='flex flex-col xl:flex-row justify-between'>
                <div className='mx-[20px] xl:mx-0'>
                    <h1 className='text-[#493843] manrope text-[32px] font-medium xl:text-[40px] text-center xl:text-left'>{t('swift1')}</h1>
                    <p className='max-w-[393px] textcolor roboto text-[14px] xl:text-[16px] font-light mt-[20px] text-center xl:text-left'>{t('swift2')}</p>
                    <div className='flex justify-between items-center mt-[40px]'>
                        <img src={img1} alt="" className='w-[93.988px] xl:w-[140px] h-[26.854px] xl:h-[40px]'/>
                        <img src={img2} alt="" className='xl:w-[72px] xl:h-[56px] w-[48.337px] h-[37.595px]'/>
                        <img src={img3} alt="" className='xl:w-[85px] xl:h-[27px] w-[57.064px] h-[18.126px]'/>
                    </div>
                    <div className='flex items-center mt-[25px]'>
                        <img src={img4} alt="" className='xl:w-[75px] xl:h-[45px] w-[50.351px] h-[30.21px] ml-[30px]'/>
                        <img src={img5} alt="" className='ml-[60px] xl:ml-[90px] xl:w-[140px] xl:h-[65px] w-[93.988px] h-[43.637px]'/> 
                        <img src={img6} alt="" className='xl:w-[65px] xl:h-[65px] w-[43.637px] h-[43.637px] ml-[50px] xl:ml-[65px]'/>
                    </div>    
                </div>
                <div className='mt-[80px] xl:mt-0 mx-[20px] xl:mx-0 xl:w-[600px] h-[314px] rounded-[20px] bg-[#493843] flex flex-col items-center justify-center'> 
                    <h1 className='manrope text-white text-[16px] xl:text-[24px] font-medium'>{t('swift3')}</h1>
                    <h1 className='manrope text-white text-[24px] xl:text-[32px] font-medium'>{t('swift4')}</h1>
                    <p className='max-w-[289px] colorblocktextt text-center mt-[14px]'>{t('swift5')}</p>
                    <div className='buttonstart mt-[44px]' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
                        <p className='text-[#493843] text-[16px] roboto'>{t('swift6')}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Swift;