import React, { useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import Main from '../components/Home/Main'
import OptiInvest from '../components/Home/OptiInvest'
import PopularTrade from '../components/Home/PopularTrade'
import DiscoverOver from '../components/Home/DiscoverOver'
import Solution from '../components/Home/Solution'
import InfoNeed from '../components/Home/InfoNeed'
import BrandBuilt from '../components/Home/BrandBuilt'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='pt-[60px] bg-[#EDF2EF] relative overflow-hidden'>
      <Main />
      <LazyLoad><OptiInvest /></LazyLoad>
      <LazyLoad><PopularTrade /></LazyLoad>
      <LazyLoad><DiscoverOver /></LazyLoad>
      <LazyLoad><Solution /></LazyLoad>
      <LazyLoad><InfoNeed /></LazyLoad>
      <LazyLoad><BrandBuilt /></LazyLoad>
    </div>
  );
}

export default Home;