import React from 'react';

import { useTranslation } from 'react-i18next';

function Whychoose() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF]'>
        <div className='max-w-[1280px] mx-auto mt-[120px]'>
            <div className='xl:rounded-[20px] bg-[#DEE2E1] flex flex-col justify-center items-center'>
                <h1 className='text-[32px] xl:text-[40px] manrope text-[#493843] font-medium pt-[80px]'>{t('company13')}</h1>
                <p className='max-w-[389px] textcolor mt-[20px] roboto text-center mx-[20px] xl:mx-0'>{t('company14')}</p>
                <div className='flex flex-col xl:flex-row gap-[20px] mt-[60px]'>
                    <div className='mx-[20px] xl:mx-0 xl:w-[497px] h-[322px] bg-[#EDF2EF] rounded-[20px]'> 
                        <div className='mt-[25px] mx-[20px] xl:mx-0 xl:ml-[40px]'>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 2.41797L5 7.87329V19.9985C5 33.6997 19.5557 37.4265 19.7021 37.4631L20 37.5352L20.2979 37.4631C20.4443 37.4265 35 33.6997 35 19.9985V7.87329L20 2.41797ZM17.5 26.7661L11.6162 20.8823L13.3838 19.1147L17.5 23.231L26.6162 14.1147L28.3838 15.8823L17.5 26.7661Z" fill="#F5B700"/>
                            </svg>
                            <h1 className='manrope text-[#493843] text-[16px] xl:text-[20px] font-medium mt-[20px]'>{t('company15')}</h1>
                            <h1 className='manrope text-[16px] xl:text-[20px] uppercase text-[#A1B5B2] mt-[5px] font-medium'>{t('company16')}</h1>
                            <p className='textcolor font-light max-w-[417px] roboto text-[14px] xl:text-[16px] mt-[20px]'>{t('company17')}</p> 
                        </div>
                    </div>
                    <div className='mx-[20px] xl:mx-0  xl:w-[497px] h-[322px] bg-[#EDF2EF] rounded-[20px]'> 
                        <div className='mt-[25px] mx-[20px] xl:mx-0 xl:ml-[40px]'>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.96 12.8011C25.6 12.8011 26.24 12.4811 26.4 11.8411L28.8 7.20109H31.36C32 8.48109 33.44 9.44109 35.04 9.44109C37.28 9.44109 39.2 7.52109 39.2 5.28109C39.2 3.04109 37.28 1.12109 35.04 1.12109C33.28 1.12109 31.68 2.24109 31.04 3.84109H27.68C27.04 3.84109 26.4 4.16109 26.24 4.80109L24 9.60109H20.16H16L13.76 4.80109C13.44 4.32109 12.8 4.00109 12.16 4.00109H8.96005C8.32005 2.40109 6.88005 1.28109 4.96005 1.28109C2.72005 1.28109 0.800049 3.20109 0.800049 5.44109C0.800049 7.68109 2.72005 9.60109 4.96005 9.60109C6.56005 9.60109 7.84005 8.64109 8.64005 7.36109H11.2L13.6 12.0011C13.76 12.4811 14.4 12.8011 15.04 12.8011H18.24V18.2411H10.72C10.08 16.8011 8.64005 15.6811 6.88005 15.6811C4.64005 15.6811 2.72005 17.6011 2.72005 19.8411C2.72005 22.0811 4.64005 24.0011 6.88005 24.0011C8.64005 24.0011 10.08 23.0411 10.72 21.4411H18.24V27.2011H15.04C14.4 27.2011 13.76 27.5211 13.6 28.1611L11.2 32.8011H8.64005C8.00005 31.5211 6.56005 30.5611 4.96005 30.5611C2.72005 30.5611 0.800049 32.4811 0.800049 34.7211C0.800049 36.9611 2.72005 38.8811 4.96005 38.8811C6.72005 38.8811 8.32005 37.7611 8.96005 36.1611H12.32C12.96 36.1611 13.6 35.8411 13.76 35.2011L16 30.4011H19.84H24L26.4 35.0411C26.72 35.6811 27.2 36.0011 27.84 36.0011H31.2C31.84 37.6011 33.28 38.7211 35.2 38.7211C37.44 38.7211 39.36 36.8011 39.36 34.5611C39.36 32.3211 37.44 30.4011 35.2 30.4011C33.6 30.4011 32.32 31.3611 31.52 32.6411H28.8L26.4 28.0011C26.24 27.5211 25.6 27.2011 24.96 27.2011H21.6V21.7611H29.28C29.92 23.2011 31.36 24.3211 33.12 24.3211C35.36 24.3211 37.28 22.4011 37.28 20.1611C37.28 17.9211 35.36 16.0011 33.12 16.0011C31.36 16.0011 29.92 16.9611 29.28 18.5611H21.6V12.8011H24.96Z" fill="#F5B700"/>
                            </svg> 
                            <h1 className='manrope text-[#493843] text-[16px] xl:text-[20px] font-medium mt-[20px]'>{t('company18')}</h1>
                            <h1 className='manrope text-[16px] xl:text-[20px] uppercase text-[#A1B5B2] mt-[5px] font-medium'>{t('company19')}</h1>
                            <p className='textcolor font-light max-w-[417px] roboto text-[14px] xl:text-[16px] mt-[20px]'>{t('company20')}</p> 
                        </div>
                    </div>
                </div>
                <div className='flex flex-col xl:flex-row gap-[20px] mt-[20px] pb-[90px]'>
                    <div className='mx-[20px] xl:mx-0  xl:w-[497px] h-[322px] bg-[#EDF2EF] rounded-[20px]'> 
                        <div className='mt-[25px] mx-[20px] xl:mx-0 xl:ml-[40px]'>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.4766 16.5566V1.35938H8.52332V16.5566H0L20 38.6434L40 16.5566H31.4766ZM22.8727 23.1637C22.3977 23.6847 21.8179 24.0316 21.1467 24.1968V25.6231H19.1058V24.2088C17.6182 23.8314 16.5113 22.6434 16.1804 21.0383L16.1248 20.7687L17.9958 19.6364L18.1354 20.1277C18.5646 21.6376 19.1228 22.2259 20.1263 22.2259C20.659 22.2259 21.0407 22.0689 21.3279 21.7321C21.547 21.4642 21.6606 21.1068 21.6606 20.6758C21.6606 19.9974 21.1914 19.5448 19.801 18.8826C18.8768 18.4626 18.4386 18.1946 17.8535 17.6906C17.2001 17.11 16.8979 16.4011 16.8979 15.4556C16.8979 14.0143 17.7908 12.7978 19.1058 12.3929V10.9656H21.1467V12.4047C22.035 12.6643 22.7307 13.2798 23.2177 14.2383L23.3766 14.551L21.6141 15.6929L21.4108 15.3309C20.9489 14.5079 20.5438 14.347 20.0001 14.347C19.4845 14.347 19.0651 14.7947 19.0651 15.3451C19.0651 15.7137 19.145 15.9899 19.5521 16.3079L19.5744 16.3233C19.8053 16.482 19.988 16.6076 20.1359 16.6886L20.9838 17.1284C21.6078 17.4403 21.9349 17.6102 22.4905 18.0309C23.3772 18.6913 23.7777 19.4683 23.8274 20.6275L23.8278 20.6441C23.8277 21.6295 23.5064 22.4772 22.8727 23.1637Z" fill="#F5B700"/>
                            </svg> 
                            <h1 className='manrope text-[#493843] text-[16px] xl:text-[20px] font-medium mt-[20px]'>{t('company21')}</h1>
                            <h1 className='manrope text-[16px] xl:text-[20px] uppercase text-[#A1B5B2] mt-[5px] font-medium'>{t('company22')}</h1>
                            <p className='textcolor font-light max-w-[417px] roboto text-[14px] xl:text-[16px] mt-[20px]'>{t('company23')}</p> 
                        </div>
                    </div>
                    <div className='mx-[20px] xl:mx-0  xl:w-[497px] h-[322px] bg-[#EDF2EF] rounded-[20px]'> 
                        <div className='mt-[25px] mx-[20px] xl:mx-0 xl:ml-[40px]'>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.5254 22.678V25.8766H16.4746V22.6781L0 18.5595V37.6441H40V18.5586L23.5254 22.678Z" fill="#F5B700"/>
                                <path d="M18.8184 21.1719H21.1816V23.5352H18.8184V21.1719Z" fill="#F5B700"/>
                                <path d="M16.4746 20.2627V18.8263H23.5254V20.2626L40 16.1432V7.05859H28.2324V2.35156H11.7676V7.05859H0V16.144L16.4746 20.2627ZM14.1113 4.69531H25.8887V7.05859H14.1113V4.69531Z" fill="#F5B700"/>
                            </svg> 
                            <h1 className='manrope text-[#493843] text-[16px] xl:text-[20px] font-medium mt-[20px]'>{t('company24')}</h1>
                            <h1 className='manrope text-[16px] xl:text-[20px] uppercase text-[#A1B5B2] mt-[5px] font-medium'>{t('company25')}</h1>
                            <p className='textcolor font-light max-w-[417px] roboto text-[14px] xl:text-[16px] mt-[20px]'>{t('company26')}</p> 
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  );
}

export default Whychoose;