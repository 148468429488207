import React from 'react';
import { useTranslation } from 'react-i18next';

import img1 from '../../img/Home/info3.png';
import img2 from '../../img/Home/info2.png';
import img3 from '../../img/Home/info1.png';
import vector from '../../img/Home/vector.svg';

import Fade from 'react-reveal/Fade';

function InfoNeed() {
  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF] pt-[120px] xl:pt-[160px]'>
      <div className='max-w-[1280px] mx-auto mt-[80px] z-10 relative'>
        <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] text-center font-medium ml-[10px] xl:ml-0 mr-[10px]'>
          {t('information_title')}
        </h1>
        <p className='textcolor text-center roboto font-light text-[14px] md:text-[16px] xl:w-[497px] mt-[20px] xl:mx-auto ml-[10px] mr-[10px]'>
          {t('information_description')}
        </p>
        <div className='flex flex-wrap xl:flex-row justify-center gap-[100px] mt-[50px]'>
          <Fade bottom>
            <div>
              <img src={img1} alt="" className='w-[166px] h-[166px] mx-auto' />
              <h1 className='font-medium manrope text-[16px] md:text-[20px] textcolor mt-[20px] text-center'>
                {t('economic_calendar')}
              </h1>
              <p className='textcolor mt-[10px] font-light max-w-[316px] roboto text-center'>
                {t('economic_calendar_description')}
              </p>
            </div>
          </Fade>
          <Fade bottom delay={300}>
            <div>
              <img src={img2} alt="" className='w-[166px] h-[166px] mx-auto' />
              <h1 className='font-medium manrope text-[16px] md:text-[20px] textcolor mt-[20px] text-center'>
                {t('market_news')}
              </h1>
              <p className='textcolor mt-[10px] font-light max-w-[316px] roboto text-center'>
                {t('market_news_description')}
              </p>
            </div>
          </Fade>
          <Fade bottom delay={600}>
            <div>
              <img src={img3} alt="" className='w-[166px] h-[166px] mx-auto' />
              <h1 className='font-medium manrope text-[16px] md:text-[20px] textcolor mt-[20px] text-center'>
                {t('learn_to_trade')}
              </h1>
              <p className='textcolor mt-[10px] font-light max-w-[316px] roboto text-center'>
                {t('learn_to_trade_description')}
              </p>
            </div>
          </Fade>
        </div>
        <div className='mt-[120px] xl:mt-[160px] flex flex-col xl:flex-row'>
          <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] font-medium ml-[10px] xl:ml-0 mr-[10px] max-w-[476px]'>
            {t('factors_title')}
          </h1>
          <div className='flex flex-col md:flex-row md:flex-wrap gap-x-[140px] ml-[10px] xl:ml-0 mr-[10px] xl:mr-0 mt-[15px]'>
            <div className='flex mt-[10px] xl:mt-0'>
              <img src={vector} className='w-[14px] h-[14px] mt-[10px]' alt="" />
              <p className='textcolor text-[16px] md:text-[20px] ml-[16px] font-medium manrope'>
                {t('supply_and_demand')}
              </p>
            </div>
            <div className='flex mt-[10px] xl:mt-0'>
              <img src={vector} className='w-[14px] h-[14px] mt-[10px]' alt="" />
              <p className='textcolor text-[16px] md:text-[20px] ml-[16px] font-medium manrope'>
                {t('inflation')}
              </p>
            </div>
          </div>
          <div className='flex flex-col md:flex-row md:flex-wrap gap-x-[140px] ml-[10px] xl:ml-0 mr-[10px] xl:mr-0 xl:mt-[15px]'>
            <div className='flex mt-[10px] xl:mt-0'>
              <img src={vector} className='w-[14px] h-[14px] mt-[10px]' alt="" />
              <p className='textcolor text-[16px] md:text-[20px] ml-[16px] font-medium manrope'>
                {t('monetary_policy')}
              </p>
            </div>
            <div className='flex mt-[10px] xl:mt-0'>
              <img src={vector} className='w-[14px] h-[14px] mt-[10px]' alt="" />
              <p className='textcolor text-[16px] md:text-[20px] ml-[16px] font-medium manrope'>
                {t('us_dollar')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoNeed;
