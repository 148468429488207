import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import svg from '../../img/Products/svgcheck.svg'

import block1 from '../../img/Products/block1.png'
import block2 from '../../img/Products/block2.png'
import block3 from '../../img/Products/block3.png'
import block4 from '../../img/Products/block4.png'
import block5 from '../../img/Products/block5.png'

function PopularTrade() {

  const [activeIndex, setActiveIndex] = useState(0);

  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF]'>
        <div className='max-w-[1280px] mx-auto mt-[80px] z-10 relative'>
            <h1 className='text-[#493843] manrope text-[32px] md:text-[46px] text-center font-semibold ml-[20px] xl:ml-0 mr-[20px]'>{t('populartrade1')}</h1>
            <div className='flex flex-col mt-[60px]'>
                <div className='flex flex-wrap justify-center gap-[20px] xl:gap-[75px]'>
                    <h1 className={activeIndex === 0 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(0)}>{t('populartrade2')}</h1>
                    <h1 className={activeIndex === 1 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(1)}>{t('populartrade3')}</h1>
                    <h1 className={activeIndex === 2 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(2)}>{t('populartrade4')}</h1>
                    <h1 className={activeIndex === 3 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(3)}>{t('populartrade5')}</h1>
                    <h1 className={activeIndex === 4 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(4)}>{t('populartrade6')}</h1>
                </div>
                {activeIndex === 0 && (
                  <div>
                    <div className='w-auto xl:h-[328px] rounded-[20px] bg-[#DEE2E1] mt-[40px] mx-[20px] xl:mx-0'>
                      <div className='flex flex-col xl:flex-row'>
                        <div className='xl:w-1/2 flex flex-col justify-center mx-[20px] xl:mx-0 mt-[30px] xl:mt-0'>
                          <h1 className='manrope text-[#493843] text-[24px] xl:text-[40px] font-medium xl:text-center'>{t('populartrade7')}</h1>
                          <p className='textcolor xl:text-center mt-[10px]'>{t('populartrade8')}</p>
                          <p className='uppercase xl:text-center mt-[20px] manrope text-[#A1B5B2] font-medium text-[20px]'>{t('populartrade9')}</p>
                        </div>
                        <div className='ml-auto'>
                          <img src={block1} alt="" className='mix-blend-darken'/>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mt-[60px] mx-[20px] xl:mx-0 justify-between'>
                      <div className='max-w-[316px]'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade10')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade11')}</p>
                        <h1 className='mt-[40px] textcolor manrope text-[20px] font-medium'>{t('populartrade12')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade13')}</p>
                      </div>
                      <div className='max-w-[316px] flex flex-col mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade14')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade15')}</p>
                        <div className='mt-[40px] xl:mt-[20px]'>
                          <div className='flex items-start'>
                            <img src={svg} alt="" className='mt-[5px]'/>
                            <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade16')}</p>
                          </div>
                          <div className='flex mt-[10px] items-start'>
                            <img src={svg} alt="" className='mt-[5px]'/>
                            <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade17')}</p>
                          </div>
                          <div className='flex mt-[10px] items-start'>
                            <img src={svg} alt="" className='mt-[5px]'/>
                            <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade18')}</p>
                          </div>
                          <div className='flex mt-[10px] items-start'>
                            <img src={svg} alt="" className='mt-[5px]'/>
                            <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade19')}</p>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col mt-[40px] xl:mt-0 xl:w-[393px] h-[288px] rounded-[20px] bg-[#493843] justify-center items-center'>
                        <h1 className='text-center manrope text-white text-[32px] font-medium'>{t('populartrade20')}</h1>
                        <p className='text-center textcolorwhite mt-[10px] roboto'><Trans>{t('populartrade21')}</Trans></p>
                        <div className='mt-[40px] w-[132px] h-[47px] flex justify-center items-center bg-[#F5B700] rounded-[40px] cursor-pointer' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
                          <p className='text-[#493843] text-[16px] roboto'>{t('populartrade22')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeIndex === 1 && (
                  <div>
                    <div className='w-auto xl:h-[328px] rounded-[20px] bg-[#DEE2E1] mt-[40px] mx-[20px] xl:mx-0'>
                      <div className='flex flex-col xl:flex-row'>
                        <div className='xl:w-1/2 flex flex-col justify-center mx-[20px] xl:mx-0 mt-[30px] xl:mt-0'>
                          <h1 className='manrope text-[#493843] text-[24px] xl:text-[40px] font-medium xl:text-center'>{t('populartrade23')}</h1>
                          <p className='textcolor xl:text-center mt-[10px]'><Trans>{t('populartrade24')}</Trans></p>
                        </div>
                        <div className='ml-auto'>
                          <img src={block2} alt="" className='mix-blend-darken'/>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0 justify-between mt-[60px]'>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade25')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade26')}</p>
                      </div>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade27')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade28')}</p>
                      </div>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade29')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade30')}</p>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0 justify-between mt-[60px]'>
                      <div className='max-w-[356px]'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade31')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade32')}</p>
                        <div className='flex items-start mt-[40px] xl:mt-[20px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade33')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade34')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade35')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade36')}</p>
                        </div>
                      </div>
                      <div className='flex flex-col mt-[40px] xl:mt-0 xl:w-[786px] h-[288px] rounded-[20px] bg-[#493843] justify-center items-center'>
                        <h1 className='text-center manrope text-white text-[32px] font-medium'>{t('populartrade37')}</h1>
                        <p className='text-center textcolorwhite mt-[10px] roboto'><Trans>{t('populartrade38')}</Trans></p>
                        <div className='mt-[40px] w-[132px] h-[47px] flex justify-center items-center bg-[#F5B700] rounded-[40px] cursor-pointer' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
                          <p className='text-[#493843] text-[16px] roboto'>{t('populartrade39')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeIndex === 2 && (
                  <div>
                    <div className='w-auto xl:h-[328px] rounded-[20px] bg-[#DEE2E1] mt-[40px] mx-[20px] xl:mx-0'>
                      <div className='flex flex-col xl:flex-row'>
                        <div className='xl:w-1/2 flex flex-col justify-center mx-[20px] xl:mx-0 mt-[30px] xl:mt-0'>
                          <h1 className='manrope text-[#493843] text-[24px] xl:text-[40px] font-medium xl:text-center'>{t('populartrade40')}</h1>
                          <p className='textcolor xl:text-center mt-[10px]'><Trans>{t('populartrade41')}</Trans></p>
                        </div>
                        <div className='ml-auto'>
                          <img src={block3} alt="" className='mix-blend-darken'/>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0 justify-between mt-[60px]'>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade42')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade43')}</p>
                      </div>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade44')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade45')}</p>
                      </div>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade46')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade47')}</p>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0 justify-between mt-[60px]'>
                      <div className='max-w-[356px]'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade48')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade49')}</p>
                        <div className='flex items-start mt-[40px] xl:mt-[20px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade50')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade51')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade52')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade53')}</p>
                        </div>
                      </div>
                      <div className='flex flex-col mt-[40px] xl:mt-0 xl:w-[786px] h-[288px] rounded-[20px] bg-[#493843] justify-center items-center'>
                        <h1 className='text-center manrope text-white text-[32px] font-medium'>{t('populartrade54')}</h1>
                        <p className='text-center textcolorwhite mt-[10px] roboto'><Trans>{t('populartrade55')}</Trans></p>
                        <div className='mt-[40px] w-[132px] h-[47px] flex justify-center items-center bg-[#F5B700] rounded-[40px] cursor-pointer' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
                          <p className='text-[#493843] text-[16px] roboto'>{t('populartrade56')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeIndex === 3 && (
                  <div>
                    <div className='w-auto xl:h-[328px] rounded-[20px] bg-[#DEE2E1] mt-[40px] mx-[20px] xl:mx-0'>
                      <div className='flex flex-col xl:flex-row'>
                        <div className='xl:w-1/2 flex flex-col justify-center mx-[20px] xl:mx-0 mt-[30px] xl:mt-0'>
                          <h1 className='manrope text-[#493843] text-[24px] xl:text-[40px] font-medium xl:text-center'>{t('populartrade57')}</h1>
                          <p className='textcolor xl:text-center mt-[10px]'>{t('populartrade58')}</p>
                          <p className='uppercase xl:text-center mt-[20px] manrope text-[#A1B5B2] font-medium text-[20px]'>{t('populartrade59')}</p>
                        </div>
                        <div className='ml-auto'>
                          <img src={block4} alt="" className='mix-blend-darken'/>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0 justify-between mt-[60px]'>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade60')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade61')}</p>
                      </div>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade62')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade63')}</p>
                      </div>
                      <div className='max-w-[316px] mt-[40px] xl:mt-0'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade64')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade65')}</p>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0 justify-between mt-[60px]'>
                      <div className='max-w-[356px]'>
                        <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade66')}</h1>
                        <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade67')}</p>
                        <div className='flex items-start mt-[40px] xl:mt-[20px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade68')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade69')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade70')}</p>
                        </div>
                        <div className='flex items-start mt-[10px]'>
                          <img src={svg} alt="" className='mt-[5px]'/>
                          <p className='textcolor roboto text-[16px] ml-[18px]'>{t('populartrade71')}</p>
                        </div>
                      </div>
                      <div className='flex flex-col mt-[40px] xl:mt-0 xl:w-[786px] h-[288px] rounded-[20px] bg-[#493843] justify-center items-center'>
                        <h1 className='text-center manrope text-white text-[32px] font-medium'>{t('populartrade72')}</h1>
                        <p className='text-center textcolorwhite mt-[10px] roboto'><Trans>{t('populartrade73')}</Trans></p>
                        <div className='mt-[40px] w-[132px] h-[47px] flex justify-center items-center bg-[#F5B700] rounded-[40px] cursor-pointer' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
                          <p className='text-[#493843] text-[16px] roboto'>{t('populartrade74')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeIndex === 4 && (
                  <div>
                    <div className='w-auto xl:h-[328px] rounded-[20px] bg-[#DEE2E1] mt-[40px] mx-[20px] xl:mx-0'>
                      <div className='flex flex-col xl:flex-row'>
                        <div className='xl:w-1/2 flex flex-col justify-center mx-[20px] xl:mx-0 mt-[30px] xl:mt-0'>
                          <h1 className='manrope text-[#493843] text-[24px] xl:text-[40px] font-medium xl:text-center'>{t('populartrade75')}</h1>
                          <p className='textcolor xl:text-center mt-[10px]'>{t('populartrade76')}</p>
                          <p className='uppercase xl:text-center mt-[20px] manrope text-[#A1B5B2] font-medium text-[20px]'>{t('populartrade77')}</p>
                        </div>
                        <div className='ml-auto'>
                          <img src={block5} alt="" className='mix-blend-darken mt-[10px]'/>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mx-[20px] xl:mx-0 justify-between mt-[60px]'>
                      <div>
                        <div className='max-w-[316px] xl:max-w-[497px]'>
                          <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade78')}</h1>
                          <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade79')}</p>
                        </div>
                        <div className='max-w-[316px] xl:max-w-[497px] mt-[40px] xl:mt-[60px]'>
                          <h1 className='textcolor manrope text-[20px] font-medium'>{t('populartrade80')}</h1>
                          <p className='roboto textcolor mt-[10px] font-light'>{t('populartrade81')}</p>
                        </div>
                      </div>
                      <div className='flex flex-col xl:w-[600px] h-[288px] rounded-[20px] bg-[#493843] justify-center items-center mt-[40px] xl:mt-0'>
                        <h1 className='text-center manrope text-white text-[32px] font-medium'>{t('populartrade82')}</h1>
                        <p className='text-center textcolorwhite mt-[10px] roboto'><Trans>{t('populartrade83')}</Trans></p>
                        <div className='mt-[40px] w-[132px] h-[47px] flex justify-center items-center bg-[#F5B700] rounded-[40px] cursor-pointer' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
                          <p className='text-[#493843] text-[16px] roboto'>{t('populartrade84')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='flex flex-wrap justify-center gap-[20px] xl:gap-[75px] mt-[60px]'>
                    <h1 className={activeIndex === 0 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(0)}>{t('populartrade2')}</h1>
                    <h1 className={activeIndex === 1 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(1)}>{t('populartrade3')}</h1>
                    <h1 className={activeIndex === 2 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(2)}>{t('populartrade4')}</h1>
                    <h1 className={activeIndex === 3 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(3)}>{t('populartrade5')}</h1>
                    <h1 className={activeIndex === 4 ? 'textpopularactive' : 'textpopularnonactive'} onClick={() => setActiveIndex(4)}>{t('populartrade6')}</h1>
                </div>
            </div>
        </div>
    </div>
  );
}

export default PopularTrade;