import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import overview1 from '../../img/Accounts/overview4.svg'
import overview2 from '../../img/Accounts/overview3.svg'
import overview3 from '../../img/Accounts/overview2.svg'
import overview4 from '../../img/Accounts/overview1.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';

import 'swiper/css';

function Overview() {

  const { t } = useTranslation();

  const [swiper, setSwiper] = useState({});

  return (
    <div className='bg-[#EDF2EF] pt-[120px] xl:pt-[160px]'>
        <div className='max-w-[1280px] mx-auto'>
            <h1 className='max-w-[335px] xl:max-w-[473px] mx-auto text-center text-[#493843] manrope text-[32px] xl:text-[40px] font-medium'>Account Overview</h1>
            <div className='xl:flex hidden mt-[60px] justify-center'>
                <div className='w-[226px]'>
                    <p className='mt-[100px] textcolor roboto text-[16px] font-light'>{t('accounts10')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts11')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts11')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts12')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts13')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts14')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts15')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts16')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts17')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'>{t('accounts18')}</p>
                    <p className='mt-[14px] textcolor roboto text-[16px] font-light'></p>
                </div>
                <div className='flex flex-col items-center w-[226px] bg-[#DEE2E1] rounded-[20px]'>
                    <img src={overview1} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                    <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>Standard Account</h1>
                    <p className='textcolor roboto text-[16px] mt-[22px]'>from 0.0 pips</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>$4.5 / {t('accounts22')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>0.01 {t('accounts23')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>$250</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>FX pairs & CFDs</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>5 {t('accounts21')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>up to 1:200</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>USD / EUR</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>80%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>50%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px] pb-[36px]'>{t('accounts20')}</p>
                </div>
                <div className='flex flex-col items-center w-[226px] rounded-[20px]'>
                    <img src={overview2} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                    <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>Premium Account</h1>
                    <p className='textcolor roboto text-[16px] mt-[22px]'>from 0.8 pips</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>$2.5 / {t('accounts22')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>0.01 {t('accounts23')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>$500</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>FX pairs & CFDs</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>5 {t('accounts21')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>up to 1:200</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>USD / EUR</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>80%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>50%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px] pb-[36px]'>{t('accounts20')}</p>
                </div>
                <div className='flex flex-col items-center w-[226px] bg-[#DEE2E1] rounded-[20px]'>
                    <img src={overview3} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                    <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>PRO Account</h1>
                    <p className='textcolor roboto text-[16px] mt-[22px]'>from 0.2 pips</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>{t('accounts24')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>0.01 {t('accounts23')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>$1.000</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>FX pairs & CFDs</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>5 {t('accounts21')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>up to 1:100</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>USD / EUR</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>80%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>50%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px] pb-[36px]'>{t('accounts20')}</p>
                </div>
                <div className='flex flex-col items-center w-[226px] rounded-[20px]'>
                    <img src={overview4} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                    <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>TURBO Account</h1>
                    <p className='textcolor roboto text-[16px] mt-[22px]'>from 0.0 pips</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>{t('accounts24')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>0.01 {t('accounts23')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>$10.000</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>FX pairs & CFDs</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>5 {t('accounts21')}</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>up to 1:100</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>USD / EUR</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>80%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px]'>50%</p>
                    <p className='textcolor roboto text-[16px] mt-[14px] pb-[36px]'>{t('accounts20')}</p>
                </div>
            </div>
            <div className='xl:hidden blockrelative z-50'>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    spaceBetween={10}
                    className='mt-[40px]'
                    loop={true}
                    onInit={(e) => {
                        setSwiper(e);
                      }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 30
                          },
                        }}
                >
                    <SwiperSlide>
                        <div className='bg-[#DEE2E1] rounded-[20px] flex flex-col mx-[20px] items-center'>
                            <img src={overview1} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                            <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>Standard Account</h1>
                            <div className='flex gap-[30px]'>
                                <div>
                                    <p className='mt-[20px] textcolor roboto text-[14px] font-light'>{t('accounts10')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts12')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts13')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts14')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts15')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light max-w-[126px]'>{t('accounts16')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts17')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts18')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts19')}</p>
                                </div>
                                <div>
                                    <p className='textcolor roboto text-[14px] mt-[20px]'>from 0.0 pips</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>$4.5 / {t('accounts22')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>0.01 {t('accounts23')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>$250</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>FX pairs & CFDs</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>5 {t('accounts21')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>up to 1:200</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>USD / EUR</p>
                                    <p className='textcolor roboto text-[14px] mt-[34px]'>80%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>50%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px] pb-[36px]'>{t('accounts20')}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bg-[#DEE2E1] rounded-[20px] flex flex-col mx-[20px] items-center'>
                            <img src={overview2} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                            <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>Premium Account</h1>
                            <div className='flex gap-[30px]'>
                                <div>
                                    <p className='mt-[20px] textcolor roboto text-[14px] font-light'>{t('accounts10')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts12')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts13')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts14')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts15')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light max-w-[126px]'>{t('accounts16')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts17')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts18')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts19')}</p>
                                </div>
                                <div>
                                    <p className='textcolor roboto text-[14px] mt-[20px]'>from 0.8 pips</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>$2.5 / {t('accounts22')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>0.01 {t('accounts23')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>$500</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>FX pairs & CFDs</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>5 {t('accounts21')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>up to 1:200</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>USD / EUR</p>
                                    <p className='textcolor roboto text-[14px] mt-[34px]'>80%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>50%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px] pb-[36px]'>{t('accounts20')}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bg-[#DEE2E1] rounded-[20px] flex flex-col mx-[20px] items-center'>
                            <img src={overview3} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                            <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>PRO Account</h1>
                            <div className='flex gap-[30px]'>
                                <div>
                                    <p className='mt-[20px] textcolor roboto text-[14px] font-light'>{t('accounts10')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts12')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts13')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts14')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts15')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light max-w-[126px]'></p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts17')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts18')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts19')}</p>
                                </div>
                                <div>
                                    <p className='textcolor roboto text-[14px] mt-[20px]'>from 0.2 pips</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>{t('accounts24')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>0.01 {t('accounts23')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>$1.000</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>FX pairs & CFDs</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>5 {t('accounts21')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>up to 1:100</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>USD / EUR</p>
                                    <p className='textcolor roboto text-[14px] mt-[34px]'>80%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>50%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px] pb-[36px]'>{t('accounts20')}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bg-[#DEE2E1] rounded-[20px] flex flex-col mx-[20px] items-center'>
                            <img src={overview3} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                            <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>PRO Account</h1>
                            <div className='flex gap-[30px]'>
                                <div>
                                    <p className='mt-[20px] textcolor roboto text-[14px] font-light'>{t('accounts10')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts12')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts13')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts14')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts15')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light max-w-[126px]'>{t('accounts16')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'></p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts18')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts19')}</p>
                                </div>
                                <div>
                                    <p className='textcolor roboto text-[14px] mt-[20px]'>from 0.2 pips</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>{t('accounts24')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>0.01 {t('accounts23')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>$1.000</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>FX pairs & CFDs</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>5 {t('accounts21')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>up to 1:100</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>USD / EUR</p>
                                    <p className='textcolor roboto text-[14px] mt-[34px]'>80%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>50%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px] pb-[36px]'>{t('accounts20')}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bg-[#DEE2E1] rounded-[20px] flex flex-col mx-[20px] items-center'>
                            <img src={overview4} alt="" className='w-[30px] h-[30px] mt-[12px]'/>
                            <h1 className='manrope text-[16px] font-bold uppercase mt-[14px] text-[#493843]'>TURBO Account</h1>
                            <div className='flex gap-[30px]'>
                                <div>
                                    <p className='mt-[20px] textcolor roboto text-[14px] font-light'>{t('accounts10')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts11')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts12')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts13')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts14')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts15')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light max-w-[126px]'>{t('accounts16')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts17')}</p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'></p>
                                    <p className='mt-[12px] textcolor roboto text-[14px] font-light'>{t('accounts19')}</p>
                                </div>
                                <div>
                                    <p className='textcolor roboto text-[14px] mt-[20px]'>from 0.0 pips</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>{t('accounts24')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>0.01 {t('accounts23')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>$10.000</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>FX pairs & CFDs</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>5 {t('accounts21')}</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>up to 1:100</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>USD / EUR</p>
                                    <p className='textcolor roboto text-[14px] mt-[34px]'>80%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px]'>50%</p>
                                    <p className='textcolor roboto text-[14px] mt-[12px] pb-[36px]'>{t('accounts20')}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className='flex mt-[20px] justify-between mx-[20px]'>
                    <div onClick={() => swiper.slidePrev()}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="29.5" y="29.5" width="29" height="29" rx="14.5" transform="rotate(-180 29.5 29.5)" stroke="#493843"/>
                            <path d="M7.64645 14.6464C7.45118 14.8417 7.45118 15.1583 7.64645 15.3536L10.8284 18.5355C11.0237 18.7308 11.3403 18.7308 11.5355 18.5355C11.7308 18.3403 11.7308 18.0237 11.5355 17.8284L8.70711 15L11.5355 12.1716C11.7308 11.9763 11.7308 11.6597 11.5355 11.4645C11.3403 11.2692 11.0237 11.2692 10.8284 11.4645L7.64645 14.6464ZM22 14.5L8 14.5V15.5L22 15.5V14.5Z" fill="#493843"/>
                        </svg> 
                    </div>
                    <div onClick={() => swiper.slideNext()}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#493843"/>
                            <path d="M22.3536 15.3536C22.5488 15.1583 22.5488 14.8417 22.3536 14.6464L19.1716 11.4645C18.9763 11.2692 18.6597 11.2692 18.4645 11.4645C18.2692 11.6597 18.2692 11.9763 18.4645 12.1716L21.2929 15L18.4645 17.8284C18.2692 18.0237 18.2692 18.3403 18.4645 18.5355C18.6597 18.7308 18.9763 18.7308 19.1716 18.5355L22.3536 15.3536ZM8 15.5L22 15.5L22 14.5L8 14.5L8 15.5Z" fill="#493843"/>
                        </svg> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Overview;