import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

function OptiInvest() {
  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF] z-10 relative flex'>
      <div className='flex flex-col xl:flex-row w-[1280px] mx-auto'>
        <Fade bottom>
          <div className='ml-[10px] xl:ml-0 mx-auto'>
            <h1 className='manrope font-medium text-[#493843] text-[32px] md:text-[36px] xl:text-[40px] pt-[120px] md:pt-[160px]'>{t('main_title')}</h1>
            <p className='textcolor roboto text-[16px] mt-[10px]'>{t('main_subtitle')}</p>
            <p className='textcolor w-[335px] sm:w-[500px] roboto text-[14px] md:text-[16px] mt-[40px] font-light'>{t('main_description')}</p>
            <div onClick={() => window.location.href = 'https://user.opti-trade.ltd'} className='buttonstart mt-[40px]'>
              <p className='text-[#493843] roboto text-[16px]'>{t('start_now')}</p>
            </div>
          </div>
        </Fade>
        <Fade bottom delay={1000}>
          <div className='bg-[#493843] xl:w-[600px] h-[314px] flex flex-col rounded-[20px] mt-[80px] md:mt-[135px] ml-[10px] mr-[10px] xl:mr-0'>
            <p className='manrope mt-[40px] xl:mt-[44px] text-[16px] text-center text-white xl:text-[24px]'>{t('apply_minutes')}</p>
            <p className='manrope text-[20px] text-center text-white xl:text-[32px]'>{t('start_trading')}</p>
            <p className='colorblocktextt mx-auto text-center mt-[10px] roboto text-[14px] xl:text-[16px] w-[245px] md:w-[289px]'>{t('access_speeds')}</p>
            <div onClick={() => window.location.href = 'https://user.opti-trade.ltd'} className='buttonstart mt-[30px] mx-auto'>
              <p className='text-[#493843] roboto text-[16px]'>{t('start_now')}</p>
            </div>            
          </div>
        </Fade>        
      </div>
    </div>
  );
}

export default OptiInvest;
